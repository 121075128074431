import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'app/store';
import { RootState } from 'app/store/reducer';
import { IAuth0Session } from 'api';

type SessionState = {
  accessToken: string | undefined;
  isAuthenticated: boolean;
  isRefreshing: boolean;
  expiresIn: number | undefined;
  userId: string | undefined;
};

const initialState: SessionState = {
  accessToken: undefined,
  isAuthenticated: false,
  isRefreshing: false,
  expiresIn: undefined,
  userId: undefined,
};

const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    authenticate: (state: SessionState, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsRefreshing: (state: SessionState, action: PayloadAction<boolean>) => {
      state.isRefreshing = action.payload;
    },
    setSession: (state: SessionState, action: PayloadAction<IAuth0Session>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.expiresIn = action.payload.expiresIn;
      state.userId = action.payload.userId;
    },
    setAccessToken: (state: SessionState, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    clearSession: (state: SessionState) => {
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.expiresIn = undefined;
      state.userId = undefined;
    },
  },
});

export const { reducer } = session;
export const { authenticate, clearSession, setIsRefreshing, setSession, setAccessToken } = session.actions;

export function getIsAuthenticated(state: RootState): boolean {
  return state.session.isAuthenticated;
}

export function getSession(state: RootState): SessionState {
  return state.session;
}

export function login(username: string, password: string): AppThunk<void> {
  return async function loginThunk(_dispatch, _getState, api) {
    try {
      const response = await api.auth.login(username, password);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function forgotPassword(username: string): AppThunk<void> {
  return async function forgotPasswordThunk(_dispatch, _getState, api) {
    try {
      const response = await api.auth.forgotPassword(username);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function resetPassword(username: string, newPassword: string, token: string): AppThunk<void> {
  return async function resetPasswordThunk(_dispatch, _getState, api) {
    try {
      const response = await api.auth.resetPassword(username, token, newPassword);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function refreshSession(): AppThunk<Promise<IAuth0Session>> {
  return async (dispatch, _getState, api) => {
    try {
      dispatch(setIsRefreshing(true));
      const response = await api.auth.refreshSession();
      dispatch(setSession(response));
      dispatch(setIsRefreshing(false));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(clearSession());
      return Promise.reject(error);
    }
  };
}
