import { Api } from 'api';

export interface IAuth0Session {
  accessToken: string | undefined;
  scope: string | undefined;
  expiresIn: number | undefined;
  userId: string | undefined;
}

export class Session implements IAuth0Session {
  private api: Api;

  public accessToken: string | undefined = undefined;
  public expiresIn: number | undefined = undefined;
  public scope: string | undefined = undefined;
  public userId: string | undefined = undefined;

  constructor(api: Api, session: IAuth0Session | undefined) {
    this.api = api;

    if (session) {
      this.create(session);
    }
  }

  create(session: IAuth0Session) {
    this.accessToken = session.accessToken;
    this.expiresIn = session.expiresIn;
    this.scope = session.scope;
    this.userId = session.userId;
  }

  setAuth0Response(session: IAuth0Session) {
    this.accessToken = session.accessToken;
    this.expiresIn = session.expiresIn;
    this.scope = session.scope;
    this.userId = session.userId;
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }
}
