import React, { useEffect } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import {
  faArrowCircleLeft,
  faCube,
  faEnvelopeOpenText,
  faFileAlt,
  faHome,
  faHomeLg,
  faSignOut,
  faUser,
  faUsers,
  faCog,
  faChessRook,
  faTable,
} from '@fortawesome/pro-duotone-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'app/store';
import { Icon, MenuItem } from 'components';
import { routes as configurationRoutes, baseRoute as configurationBaseRoute } from 'routes/configurationRoutes';
import * as residentialRoutes from 'routes/residentialRoutes';
import { clearSession, getActiveShard, fetchShards, clearActiveShardKey } from 'utilities';

import { NavIcon, NavigationWrapper, NavText, NavTitle } from './Navigation.styles';

export const Navigation = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const active = useSelector(getActiveShard);

  useEffect(() => {
    if (!active) {
      dispatch(fetchShards());
    }
  }, [active, dispatch]);

  function handleLogout() {
    dispatch(clearSession());
    dispatch(clearActiveShardKey());
  }

  return (
    <NavigationWrapper>
      <Routes>
        <Route
          path={residentialRoutes.baseRoute + '/*'}
          element={
            <div>
              <Nav pills vertical>
                <MenuItem tag={Link} to="/" icon={faArrowCircleLeft} text="Back to Shard" />
              </Nav>

              <hr />

              <NavTitle>Residential Sign Up</NavTitle>

              <Nav pills vertical>
                <MenuItem to={residentialRoutes.homepageLinkRoute} icon={faHomeLg} text="Homepage" />
                <MenuItem to={residentialRoutes.inquiryRoute} icon={faFileAlt} text="Initial Inquiry" />
                <MenuItem
                  to={residentialRoutes.inquiryFailureRoute}
                  icon={faFileAlt}
                  text="Initial Inquiry (Failure)"
                />
                <MenuItem
                  to={residentialRoutes.inquirySuccessRoute}
                  icon={faFileAlt}
                  text="Initial Inquiry (Success)"
                />
                <MenuItem to={residentialRoutes.verifyAccountRoute} icon={faFileAlt} text="Verify Account" />
                <MenuItem to={residentialRoutes.setPasswordRoute} icon={faFileAlt} text="Set Password" />
                <MenuItem to={residentialRoutes.connectUtilityRoute} icon={faFileAlt} text="Connect Utility" />
                <MenuItem to={residentialRoutes.confirmUtilityRoute} icon={faFileAlt} text="Confirm Utility" />
                <MenuItem to={residentialRoutes.addUtilityRoute} icon={faFileAlt} text="Add Utility" />
                <MenuItem to={residentialRoutes.plansRoute} icon={faFileAlt} text="Plans" />
                <MenuItem to={residentialRoutes.reviewRoute} icon={faFileAlt} text="Review" />
                <MenuItem to={residentialRoutes.addPaymentRoute} icon={faFileAlt} text="Add Payment" />
                <MenuItem to={residentialRoutes.finalizeRoute} icon={faFileAlt} text="Finalize" />
                <MenuItem to={residentialRoutes.creditCheckRoute} icon={faFileAlt} text="Credit Check" />
                <MenuItem to={residentialRoutes.faqsRoute} icon={faFileAlt} text="FAQs" />
              </Nav>
            </div>
          }
        />

        <Route
          path="/*"
          element={
            <div>
              <NavTitle>Configure Shard</NavTitle>

              <Nav pills vertical>
                {configurationRoutes
                  .filter(({ hide }) => !hide)
                  .map(({ icon, name, path }) => {
                    return (
                      <MenuItem
                        key={name}
                        exact="true"
                        to={`${configurationBaseRoute}/${path}`}
                        icon={icon}
                        text={name}
                      />
                    );
                  })}
                <MenuItem to="/users" icon={faUsers} text="Users" />
                {active && active.isSandboxShard && (
                  <MenuItem to="/configuration/sandbox-settings" icon={faChessRook} text="Sandbox Settings" />
                )}
                {active && active.isDemoShard && (
                  <MenuItem to="/configuration/sandbox-settings" icon={faCog} text="Demo Settings" />
                )}
              </Nav>

              <hr />

              <NavTitle>Entities</NavTitle>

              <Nav pills vertical>
                <MenuItem to="/entities/associated-utilities" icon={faCube} text="Associated Utilities" />
              </Nav>

              <hr />

              <NavTitle>Customize Text</NavTitle>

              <Nav pills vertical>
                <MenuItem to="/email-templates" icon={faEnvelopeOpenText} text="Email Templates" />
                <MenuItem to="/residential-sign-up" icon={faHome} text="Residential Sign Up" />
                <MenuItem to="/content/privacy-policy" icon={faFileAlt} text="Privacy Policy" />
                <MenuItem to="/content/terms-of-use" icon={faFileAlt} text="Terms of Use" />
                <MenuItem to="/content/initial-dashboard" icon={faTable} text="Initial Dashboard" />
              </Nav>

              <hr />

              <Nav pills vertical>
                <MenuItem to="/account-settings" icon={faUser} text="Account Settings" />
                <NavItem>
                  <NavLink href="#" onClick={() => handleLogout()} className="d-flex align-items-center">
                    <NavIcon>
                      <Icon icon={faSignOut} />
                    </NavIcon>

                    <NavText>Sign out</NavText>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          }
        />
      </Routes>
    </NavigationWrapper>
  );
};
