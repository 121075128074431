import { Api } from 'api/Api';
import { BaseService } from './BaseService';

export class ContactService extends BaseService {
  constructor(api: Api) {
    super(api, '/contact-info-nav');
  }

  public fetch() {
    return this.get<any>(); // Use the inherited get method
  }
}
