import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { api } from 'app/store';

import { authenticate } from 'utilities';
import { useSession } from './useSession';

export function useAccount(id: any) {
  const [data, setData] = useState({});
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setError(undefined);
    setData({});
    setLoading(true);

    api.account
      .fetchAccount(id)
      .then(setData, setError)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [id]);

  return {
    data,
    error,
    loading,
  };
}

export function useUser() {
  const [data, setData] = useState({});
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setError(undefined);
    setData({});
    setLoading(true);

    api.account
      .fetchUser()
      .then(setData, setError)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return {
    data,
    error,
    loading,
  };
}

export function useAuth0Token() {
  const dispatch = useDispatch();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { setAccessToken } = useSession();

  useEffect(() => {
    getAccessTokenSilently({ detailedResponse: true }).then((response: any) => {
      if (response.access_token.length > 0 && response.access_token !== null && response.access_token !== undefined) {
        setAccessToken(response.access_token);
      }
      api.session.setAuth0Response({
        accessToken: response.access_token,
        expiresIn: response.expires_in,
        scope: response.scope,
        userId: undefined,
      });
    });

    if (isAuthenticated) {
      dispatch(authenticate(true));
    }
  }, [getAccessTokenSilently, isAuthenticated, dispatch, setAccessToken]);

  return { isAuthenticated, user };
}
