import React, { useEffect } from 'react';
import get from 'lodash/get';
import { Alert } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { Button } from 'components';

import { useAuth0 } from "@auth0/auth0-react";

export const LoginForm = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  const message = get(location, 'state.message');

  const { invitation, organization } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        organization,
        invitation
      }
    })
  }, [loginWithRedirect, invitation, organization])

  const onLoginClick = () => {
    loginWithRedirect()
  }

  return (
    <div>
      {Boolean(message) && <Alert color="info">{message}</Alert>}

      <Button block color="primary" className="mt-2 font-weight-bold" onClick={onLoginClick}>Log In w/ Okta</Button>
    </div>
  );
};
