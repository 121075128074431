import { useState } from 'react';

export function useSession() {
  const [accessToken] = useState<string | null>(localStorage.getItem('local_accessToken'));

  const setAccessToken = (accessToken: string) => {
    localStorage.setItem('local_accessToken', accessToken);
  };

  return {
    accessToken,
    setAccessToken,
  };
}
