import { Api } from 'api';
import { ContentEndpointInterfaces } from 'types';
import { BaseService } from './BaseService';

export enum CONTENT_ENDPOINTS {
  CONTENT = '/content',
  PRIVACY_POLICY = '/content/1',
  TERMS_OF_USE = '/content/3',
  INITIAL_DASHBOARD = '/initial-dashboard',
}

export class ContentService extends BaseService {
  constructor(api: Api) {
    super(api, '/shards');
  }

  public fetch(path: CONTENT_ENDPOINTS) {
    return this.get<any>(`${this.baseUrl}/${path}`);
  }

  public update<A extends CONTENT_ENDPOINTS>(path: A, values: Partial<ContentEndpointInterfaces[A]>) {
    return this.put<void>(this.baseUrl + path, values);
  }
}
